<template>
    <div>
        <el-card class="management-report-card-box">
            <el-form>
                <div class="flex flex-row justify-between items-center mb-2">
                    <h2 class="text-base font-semibold mb-2">{{ title }}</h2>
                    <div class="flex items-center">
                        <el-switch v-model="localToggleValue" active-color="#48c78e" inactive-color="#D1D5DB" @change="onToggleChange" />
                    </div>
                </div>
                <div v-if="toggleValue" v-loading="$waiting.is('loading-placedSecurities')" class="flex flex-col">
                    <el-table :data="data.acquisitionValuesTableRows">
                        <el-table-column label="Anskaffningsvärde" width="400">
                            <template slot-scope="{ row }">
                                <el-input v-model="row.description" :disabled="inputDisabled" />
                            </template>
                        </el-table-column>
                        <el-table-column label="Nuvarande år">
                            <template slot-scope="{ row }">
                                <el-input v-model="row.currentYear" :disabled="inputDisabled" />
                            </template>
                        </el-table-column>
                        <el-table-column label="Föregående år">
                            <template slot-scope="{ row }">
                                <el-input v-model="row.previousYear" :disabled="inputDisabled" />
                            </template>
                        </el-table-column>
                        <el-table-column>
                            <template slot-scope="{ $index, store, row }">
                                <template v-if="store.states.data.length !== 1">
                                    <el-button plain size="mini" @click="deleteRowTable(row, data.acquisitionValuesTableRows)" :disabled="inputDisabled">
                                        <i class="fa-solid fa-minus" />
                                    </el-button>
                                </template>
                                <template v-if="$index === store.states.data.length - 1">
                                    <el-button plain size="mini" @click="addRowTable(data.acquisitionValuesTableRows)" :disabled="inputDisabled">
                                        <i class="fa-solid fa-plus" />
                                    </el-button>
                                </template>
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-table :data="data.depreciationTableRows">
                        <el-table-column label="Avskrivningar" width="400">
                            <template slot-scope="{ row }">
                                <el-input v-model="row.description" :disabled="inputDisabled" />
                            </template>
                        </el-table-column>
                        <el-table-column>
                            <template slot-scope="{ row }">
                                <el-input v-model="row.currentYear" :disabled="inputDisabled" />
                            </template>
                        </el-table-column>
                        <el-table-column>
                            <template slot-scope="{ row }">
                                <el-input v-model="row.previousYear" :disabled="inputDisabled" />
                            </template>
                        </el-table-column>
                        <el-table-column>
                            <template slot-scope="{ $index, store, row }">
                                <template v-if="store.states.data.length !== 1">
                                    <el-button plain size="mini" @click="deleteRowTable(row, data.depreciationTableRows)" :disabled="inputDisabled">
                                        <i class="fa-solid fa-minus" />
                                    </el-button>
                                </template>
                                <template v-if="$index === store.states.data.length - 1">
                                    <el-button plain size="mini" @click="addRowTable(data.depreciationTableRows)" :disabled="inputDisabled">
                                        <i class="fa-solid fa-plus" />
                                    </el-button>
                                </template>
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-table :data="data.closingReportedValueTableRows">
                        <el-table-column width="400">
                            <template slot-scope="{ row }">
                                <el-input v-model="row.description" :disabled="inputDisabled" />
                            </template>
                        </el-table-column>
                        <el-table-column>
                            <template slot-scope="{ row }">
                                <el-input v-model="row.currentYear" :disabled="inputDisabled" />
                            </template>
                        </el-table-column>
                        <el-table-column>
                            <template slot-scope="{ row }">
                                <el-input v-model="row.previousYear" :disabled="inputDisabled" />
                            </template>
                        </el-table-column>
                        <el-table-column />
                    </el-table>
                    <p class="mt-8 p-2 text-sm">
                        Av årets nedsrkivningar avser
                        <el-input v-model="localDepreciationAmount" style="width:100px" :disabled="inputDisabled" />
                        kronor nedskrivningar till det värde som tillgången har på
                        <br />
                        balansdagen även om det inte kan antas att värdenedgången är bestående.
                    </p>
                    <el-input type="textarea" :disabled="inputDisabled" v-model="localInputText" class="break-normal custom-textarea p-2" />
                </div>
            </el-form>
            <CardEditButtons :toggle-value="toggleValue" :show-edit-button="showEditButton" :toggle-edit="toggleEdit" :close-modal="closeModal" :save-data="saveData" />
        </el-card>
    </div>
</template>
<script>
import { Switch } from "element-ui";

export default {
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: "",
        },
        tooltipInfo: {
            type: String,
            default: "",
        },
        toggleValue: {
            type: Boolean,
            default: true,
        },
        data: {
            type: Object,
            default: () => ({}),
        },
    },

    data() {
        return {
            selectedYear: null,
            financialYearId: null,
            annualReportId: undefined,
            inputDisabled: true,
            showEditButton: true,
            localToggleValue: this.toggleValue,
            localInputText: this.data.text,
            localDepreciationAmount: this.data.amount,
            initialInputText: "",
            isInitialInputTextSet: false,
        };
    },
    components: {
        "el-switch": Switch,
        CardEditButtons: () => import("../../managementStatement/components/CardEditButtons.vue"),
    },
    watch: {
        toggleValue(newVal) {
            this.localToggleValue = newVal;
        },
    },
    methods: {
        toggleEdit() {
            this.inputDisabled = !this.inputDisabled;
            this.showEditButton = !this.showEditButton;
        },
        onToggleChange(newVal) {
            const eventData = {
                source: 7,
                value: newVal,
            };
            this.$emit("toggle-change", eventData);
        },
        async saveData() {
            this.data.text = this.localInputText;
            this.data.amount = this.localDepreciationAmount;
            this.isInitialInputTextSet = false;
            this.$emit("saveData");
            this.toggleEdit();
            this.$notify.success({ title: `Sparat "${this.title}"` });
        },
        closeModal() {
            this.$emit("close");
            const load = "loading-placedSecurities";
            this.$emit("close", { load: load, inputTextNewValue: this.inputTextNewValue });
            this.toggleEdit();
        },
        addRowTable(table) {
            const newObj = {
                description: "",
                currentYear: "",
                previousYear: "",
            };
            table.push(newObj);
        },
        deleteRowTable(row, table) {
            if (table.length > 0) {
                const index = table.indexOf(row);
                if (index > -1) {
                    table.splice(index, 1);
                }
            }
        },
    },
};
</script>
